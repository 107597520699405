import {Environment} from "../types/environment";

const env = (process.env.REACT_APP_ENV as Environment) || "local";

const defaults = {
    env,
    "gtm": {
        "adSelectors": ["google-tag-ad-1"],
        "source": "//www.googletagservices.com/tag/js/gpt.js",
    },
    "prebid": "//localhost:9999/build/dev/prebid.js",
    "authsApi": "http://localhost:8089/api/v1/auths",
};

const dev = {
    ...defaults,
    "prebid":
        "https://dmd-web-static-dev-na1.s3.amazonaws.com/prebid/js/prebid.js",
    "authsApi": "https://aix.dev.hcn.health/api/v1/auths",
};

const sbx = {
    ...defaults,
    "prebid":
        "https://dmd-web-static-sbx-na1.s3.amazonaws.com/prebid/js/prebid.js",
    "authsApi": "https://aix.sbx.hcn.health/api/v1/auths",
};

const stg = {
    ...defaults,
    "prebid":
        "https://dmd-web-static-stg-na1.s3.amazonaws.com/prebid/js/prebid.js",
    "authsApi": "https://aix.stg.hcn.health/api/v1/auths",
};

const qa = {
    ...defaults,
    "prebid":
        "https://dmd-web-static-qa-na1.s3.amazonaws.com/prebid/js/prebid.js",
    "authsApi": "https://aix.qa.hcn.health/api/v1/auths",
};

const prod = {
    ...defaults,
    "prebid":
        "https://dmd-web-static-prod-na1.s3.amazonaws.com/prebid/js/prebid.js",
    "authsApi": "https://aix.hcn.health/api/v1/auths",
};

export const config = {
    "dev": dev,
    "local": defaults,
    "prod": prod,
    "qa": qa,
    "sbx": sbx,
    "stg": stg,
    "uat": defaults,
};

export default config[env];
