import {Environment} from "../types/environment";
import {EnvironmentDependent} from "../types/environment_dependent";
import {SnippetConfig} from "../types/snippet_config";

const env = (process.env.REACT_APP_ENV as Environment) || "local";

const defaults = {
    "adobeAnalytics": {
        "source":
            "//assets.adobedtm.com/launch-EN8689d0d2e7d84048be077a460fec531e.min.js",
    },
    "clientCookieSessionKey": "dmd-sid",
    "clientCookieTagKey": "dmd-id",
    "dmdCookies": {
        "clear": "/clear_cookies",
        "read": "/read_cookies",
    },
    "dmdStorage": {
        "clear": "/clear_storage",
        "read": "/read_storage",
    },
    "googleAnalytics4": {
        "apiKey": "G-C1S2W7PS9R",
        "source": "https://www.googletagmanager.com/gtag/js?id=",
    },
    "googleUniversalAnalytics": {
        "apiKey": "UA-207141006-1",
    },
    "gtm": {
        "apiKey": "GTM-5963ZLD",
        "apiKeyModernBuild": "",
    },
    "localStorageTagKey": "dmd-id",
    "version": "Legacy",
};

const local = {
    ...defaults,
    "altApiKey": "1-508-B2BFAE88",
    "apiKey": "1-473-974F652E",
    "env": "local" as const,
    "gtm": {
        "apiKey": "GTM-KT9TR2C",
        "apiKeyModernBuild": "",
    },
    "host": "//www.medtargetsystem.local",
    "reportViewApiKey": "1-1462-9AE85317",
};

const dev = {
    ...defaults,
    "altApiKey": "1-508-B2BFAE88",
    "apiKey": "1-473-974F652E",
    "env": "dev" as const,
    "host": "//dev.medtargetsystem.com",
    "reportViewApiKey": "1-1461-2E81B566",
};

const sbx = {
    ...defaults,
    "altApiKey": "1-508-B2BFAE88",
    "apiKey": "1-473-974F652E",
    "env": "sbx" as const,
    "host": "//sbx.medtargetsystem.com",
    "reportViewApiKey": "1-1466-CAC1186B",
};

const stg = {
    ...defaults,
    "altApiKey": "1-508-B2BFAE88",
    "apiKey": "1-473-974F652E",
    "env": "stg" as const,
    "host": "//stg.medtargetsystem.com",
    "reportViewApiKey": "1-1708-0C5A2601",
};

const qa = {
    ...defaults,
    "altApiKey": "1-508-B2BFAE88",
    "apiKey": "1-473-974F652E",
    "env": "qa" as const,
    "host": "//qa.medtargetsystem.com",
    "reportViewApiKey": "1-1459-5FD5059D",
};

const uat = {
    ...defaults,
    "altApiKey": "1-342-7C391F84",
    "apiKey": "1-206-E539680D",
    "env": "uat" as const,
    "host": "//uat.medtargetsystem.com",
    "reportViewApiKey": "1-1462-9AE85317",
};

const prod = {
    ...defaults,
    "altApiKey": "1-508-B2BFAE88",
    "apiKey": "1-473-974F652E",
    "env": "prod" as const,
    "gtm": {
        "apiKey": "GTM-NSRSTNX",
        "apiKeyModernBuild": "GTM-M9F28CM",
    },
    "host": "//www.medtargetsystem.com",
    "reportViewApiKey": "1-1463-4DAF7374",
};

export const config: EnvironmentDependent<SnippetConfig> = {
    "dev": dev,
    "local": local,
    "prod": prod,
    "qa": qa,
    "sbx": sbx,
    "stg": stg,
    "uat": uat,
};

export default config[env];
